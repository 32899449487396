import { PlacesModel } from '../../model/placesModel'
import { AppModel } from '../../model/appModel.service'
import * as colors from '@targomo/client'
import { MarkerSizeKeyValue } from '../sidenav/legendPanel.component'
import { colorSquare, colorSquareGeneral, colorCircle, PDFHelper } from './helper'
import { createStyle } from './styles'
import { UserEndpoint } from '../../api/user'
import { object as objects } from '@targomo/common'
import { array as arrays } from '@targomo/common'
import { Category } from '../../api/index'
import { BoundingBox } from '@targomo/core'
import { geometry } from '@targomo/core'
import { MapDialogOptions } from '../dialogs/mapPrintDialog.component'
import { TRAVEL_COLORS } from '@targomo/client'
import { APP_BASE_PATH } from '../../util/basePath'
import { SettingsEndpoint } from '../../api/settings'
import { DEFAULT_COPYRIGHT, DEFAULT_MAP_ATTRIBUTION } from '../../../common/models/settings'
import { MARKER_PRINT_FACTOR } from '../../model/displayConstants'
import { UserSettingsEndpoint } from '../../api/userSettings'
import { ExportPDFReport } from './exportPdfReport'

const Color = require('color')

const pdfMake = require('pdfmake/build/pdfmake.js')
const pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

const TABLE_OPTIONS = {
  border: '#ffffff',
  background: ['#ffffff', null],
  widths: () => ['auto', '*'],
  layout: {
    hLineWidth: function (i: number, node: any) {
      return 0
    },
    vLineWidth: function (i: number, node: any) {
      return 0
    },
    hLineColor: function (i: number, node: any) {
      return '#ffffff'
    },
    vLineColor: function (i: number, node: any) {
      return '#ffffff'
    },

    fillColor: function (i: number, node: any) {
      return '#ffffff'
    },
  },
}

const markerSizeValues: MarkerSizeKeyValue[] = [
  { key: 'netSalesArea', name: 'Net Sales Area', selected: true },
  { key: 'grossInternalArea', name: 'Gross Internal Area' },
]

export async function drawMapScale(imageData: string, scale: { width: number; label: string }) {
  return new Promise<string>((resolve) => {
    const image = new Image()
    image.src = imageData
    image.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0)

      ctx.fillStyle = '#ffffff'
      ctx.strokeStyle = '2px solid #000000'

      ctx.strokeRect(30, canvas.height - 30 - 30, scale.width, 30)
      ctx.fillRect(30, canvas.height - 30 - 30 - 2, scale.width, 30 + 2)

      // const textWidth = ctx.measureText(scale.label)
      ctx.fillStyle = '#000000'
      ctx.font = '13px "Open Sans"'
      ctx.fillText(scale.label, 30 + 5, canvas.height - 30 - 12)

      resolve(canvas.toDataURL())
    }
  })
}

export async function mapToPdf(
  imageData: string,
  zoomLevel: number,
  appModel: AppModel,
  userEndpoint: UserEndpoint,
  settingsEndpoint: SettingsEndpoint,
  bounds: BoundingBox,
  options: MapDialogOptions,
  scale: { width: number; label: string },
  userSettingsEndpoint: UserSettingsEndpoint,
  exportPDFReport: ExportPDFReport
) {
  imageData = await drawMapScale(imageData, scale)
  const user = await userEndpoint.me()
  const customLogoPath = APP_BASE_PATH + `api/users/${user.id}/companypicture/public`
  const travelOptions = await appModel.settings.travelOptionsUpdates.take(1).toPromise()
  const settings = await settingsEndpoint.getPublic()
  const userSettings = await userSettingsEndpoint.loadReport()

  const showCarLegend = await appModel.settings.roadsVolumeLayerUpdates.take(1).toPromise()
  const showTravelLegend = !(await appModel.places.sources.isEmpty())

  const printShowSizeLegend =
    (userSettings && userSettings.customReport && userSettings.customReport.printShowSizeLegend) !== false
  const printShowFasciaLegend =
    (userSettings && userSettings.customReport && userSettings.customReport.printShowFasciaLegend) !== false

  // const places = this.appModel.places.reachableFilteredPlaces.getValue()
  // const sources = this.appModel.places.sources.getValues()

  const helper = new PDFHelper(null)
  await exportPDFReport.initIcons(helper)
  const customLogo = await helper.convertImgToDataURLviaCanvas(customLogoPath, 'image/png')

  function clientLogo() {
    if (customLogo && customLogo.data) return { image: customLogo.data, fit: [140, 45], style: 'logoLegend' }
    else return { text: '' }
  }

  async function travelTimesLegend() {
    const travelMinutesGrouping = await appModel.settings.edgeWeightsColorsUpdates.take(1).toPromise()
    const travelTimesOriginal = travelMinutesGrouping.map((item: any, i) => {
      return {
        color: Color(item.color).hex(),
        index: i,
        time: item.value,
        text: item.labelRange,
      }
    })

    const rows: any[] = travelTimesOriginal
      .filter((row) => row.time <= travelOptions.maxEdgeWeight)
      .map((travelTime, i) => {
        return [
          helper.styleColorColumn(colorSquareGeneral(travelTime.color, 15, 10)),
          { text: travelTime.text, style: 'defaultCellLeft2' },
        ]
      })

    return {
      style: 'legendSection',
      stack: [{ text: 'Travel Times:', style: 'headerMap' }, helper.table(null, rows, 'defaultTable', TABLE_OPTIONS)],
    }
  }

  function markersLegend() {
    const settings = appModel.displaySettings.getValue() //this.Settings.getPublic().then(settings => this.settings = settings)

    const places = appModel.places.filteredPlaces.getValue()

    function onlyCustom() {
      const categories: any = {}
      for (let place of places) {
        if (place.id >= 0) {
          return false
        }
      }

      return true
    }

    if (onlyCustom()) {
      return null
    }

    const name = markerSizeValues.filter((item) => item.key == settings.markerSizeProperty)[0].name

    const effectiveZoom = Math.min(25, Math.max(5, zoomLevel))

    const sizes = [0, 3 * 5000, 8 * 5000].map((area) =>
      Math.round(appModel.places.getRadiusForZoomLevel(zoomLevel, area))
    )

    const rows: any[] = [
      { style: 'legend-marker-small', text: `${name} < 20,000 sq ft` },
      { style: 'legend-marker-medium', text: `${name} 20,000 to 30,000 sq ft` },
      { style: 'legend-marker-large', text: `${name} > 30,000 sq ft` },
    ].map((row: any, i: number) => {
      // if (area > 30000) {
      //   markerSize = 5
      // }  else if (area > 20000) {
      //   markerSize = 4
      // }  else if (area > 10000) {
      //   markerSize = 3
      // }  else if (area > 5000) {
      //   markerSize = 2
      // } else {
      //   markerSize = 1
      // }

      // Reference from map calculation
      // [{zoom: 5, value: 0}, 1],
      // [{zoom: 5, value: 1}, 1],
      // [{zoom: 5, value: 2}, 1],
      // [{zoom: 5, value: 3}, 1],
      // [{zoom: 5, value: 4}, 1],
      // [{zoom: 5, value: 5}, 1],
      // [{zoom: 5, value: 6}, 1],
      //
      // [{zoom: 25, value: 0}, 18],
      // [{zoom: 25, value: 1}, 23],
      // [{zoom: 25, value: 2}, 28],
      // [{zoom: 25, value: 3}, 33],
      // [{zoom: 25, value: 4}, 38],
      // [{zoom: 25, value: 5}, 43],
      // [{zoom: 25, value: 6}, 48],

      const radius = sizes[i] * 0.32 * MARKER_PRINT_FACTOR
      const color = '#3887be'
      const circle = helper.styleColorColumn(
        colorCircle(color, radius, 0, 0, Color(color).darken(0.1).hex()),
        'defaultCellColorBoxCenter'
      )

      return [circle, { text: row.text, style: 'defaultCellLeft2' }]
    })

    return {
      style: 'legendSection',
      stack: [{ text: 'Unit Sizes:', style: 'headerMap' }, helper.table(null, rows, 'defaultTable', TABLE_OPTIONS)],
    }
  }

  async function categoriesLegend() {
    const places = appModel.places.filteredPlaces.getValue()

    function initCategories(): { name: string; color: string }[] {
      const categories: any = {}
      places.forEach((place) => {
        if (geometry.contains(bounds, place)) {
          const color = place.properties['marker-type'] || place.category.color
          categories[place.category.id + '@' + color] = {
            name: place.category.name,
            color,
          }
        }
      })

      return objects.values(categories)
    }

    const sources = arrays.sortBy(initCategories(), 'name')
    if (!sources || !sources.length) return { text: '' }

    const rows: any[] = (sources || []).slice(0, 15).map((source) => {
      return [
        helper.styleColorColumn(colorSquareGeneral(source.color)),
        { text: source.name, style: 'defaultCellLeft2' },
      ]
    })

    return {
      style: 'legendSection',
      stack: [helper.table(null, rows, 'defaultTable', TABLE_OPTIONS)],
    }

    // return helper.table(null, rows, 'defaultTable')
    // return {stack: rows}
  }

  function sourcesLegend() {
    const sources = appModel.places.sources.getValues()
    if (!sources || !sources.length) return { text: '' }

    const rows: any[] = (sources || []).map((source) => {
      const joined = [source.street, source.town, source.postcode].filter((item) => !!item).join(', ')
      const text = [source.defaultName, joined].filter((item) => !!item).join(' | ')
      return { text }
    })

    return {
      stack: rows,
      absolutePosition: { x: 205, y: 40 },
    }
  }

  // function generateLegend() {
  //   return [
  //     clientLogo(),
  //     travelTimesLegend(),
  //     markersLegend(),
  //     categoriesLegend(),
  //   ]
  // }

  async function generateLegend() {
    const rows = [
      clientLogo(),
      showTravelLegend ? await travelTimesLegend() : null,
      printShowSizeLegend ? markersLegend() : null,
      printShowFasciaLegend ? await categoriesLegend() : null,
    ].filter((row) => !!row)
    // .map((item: any) => {
    //   item.style = 'mapLegendTableRow'
    //   item.border = [false, false, false, false]
    //   item.fillColor = '#ffffff'
    //   return [item]
    // })

    // return {
    //   table: {
    //     widths: ['*'],
    //     body: rows
    //   },
    //   style: 'mapLegendMainLayout',
    //   layout: TABLE_OPTIONS.layout
    // }]

    return rows
  }

  function whitePanel() {
    const panel: any = colorSquareGeneral('#ffffff', 190, 555, 0, 0)
    panel.absolutePosition = { x: 0, y: 0 }
    return panel
  }

  const copyright = [(settings && settings.copyright) || DEFAULT_COPYRIGHT, DEFAULT_MAP_ATTRIBUTION].join(', ')

  const carLegend = showCarLegend
    ? [
        {
          margin: [0, 5, 0, 0],
          columns: [
            { image: helper.icons.car, width: 11, border: [false, false, false, false] },
            {
              margin: [7, 0, 0, 0],
              fontSize: 8,
              text: ' Road traffic volumes reported are the modelled average annual daily flow of all motor vehicles, Source: A&A, DfT',
            },
          ],
        },
      ]
    : []

  const docDefinition: any = {
    footer: {
      text: copyright,
      style: 'contentFooter2',
    },
    // content: [
    //   {image: imageData,
    //     fit: [770, 500],
    //     absolutePosition: {x: 30,  y: 40}
    //   },
    //   whitePanel(),

    //   sourcesLegend(),
    //   {columns: [
    //     {stack: generateLegend(), width: 170, style: 'legendStyle'},
    //     {text: '', width: 600}
    //   ]},
    // ]

    content: [
      {
        columns: [
          { stack: await generateLegend(), width: 170, style: 'legendStyle' },
          {
            stack: [
              {
                image: imageData,
                // fit: [600, 520],
                width: 600,
                // absolutePosition: {x: 30,  y: 30}
              }, //770

              ...carLegend,
            ],
          },
        ],
        fontSize: 12,
      },
      sourcesLegend(),
    ],
    pageMargins: [30, 30, 30, 30],
    pageSize: 'A4',
    pageOrientation: 'landscape',
    styles: createStyle(null),
    maxPagesNumber: 1,
  }

  return pdfMake.createPdf(docDefinition)
}
